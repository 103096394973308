<template>
  <app-modal
    :valid="selected.length > 0"
    title="Add/Remove Project User"
    v-model="open"
    max-width="800"
    confirm-label="Save Changes"
    @confirmed="handleSave"
    @cancelled="handleCancel"
  >
    <v-card flat>
      <v-card-text>
        <v-item-group v-model="selected" multiple>
          <v-row>
            <v-col
              v-for="item in allusersExceptCurrentAdmin"
              :key="item.id"
              :cols="mdUp ? 2 : 6"
            >
              <v-item v-slot="{ active, toggle }" :value="item.id">
                <v-hover v-slot:default="{ hover }">
                  <div
                    class="xflex xflex-col xjustify-center xtext-center xitems-center"
                  >
                    <v-badge
                      bordered
                      :color="active ? `primary` : 'white'"
                      :icon="
                        active
                          ? 'mdi-check-bold'
                          : 'mdi-checkbox-blank-circle-outline'
                      "
                      overlap
                    >
                      <v-avatar
                        tile
                        size="5em"
                        :class="hover ? 'xopacity-90' : ''"
                        class="xmask xmask-squircle"
                      >
                        <v-img
                          contain
                          :src="item.image_url"
                          class="text-right pa-2 xcursor-pointer"
                          @click="toggle"
                        >
                        </v-img>
                      </v-avatar>
                    </v-badge>
                    <span>{{ item.real_name }}</span>
                  </div>
                </v-hover>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card-text>
      <!-- <pre>{{ project }}</pre> -->
    </v-card>
  </app-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  props: {
    value: { type: Boolean, default: false },
    project: { type: [Object, Array], default: undefined },
  },
  data() {
    return {
      open: false,
      selected: [],
      originals: [],
    };
  },
  computed: {
    ...mapGetters("workspace", ["allusers"]),
    allusersExceptCurrentAdmin() {
      if (!this.project || !this.project.admin) return this.allusers;
      return this.allusers.filter((i) => i.id !== this.project.admin.id);
    },
    ...mapGetters(["user"]),
    payload() {
      return {
        selected: this.selected,
        project_id: this.project.id || null,
      };
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
        val && this.user && this.fetchWorkspaceAllUsers(this.user.team_id);
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
    project: {
      handler: function (val) {
        this.selected = this.originals =
          val && val.members && val.members.length
            ? val.members.map((i) => i.id)
            : [];
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("projects", ["setProject", "storeProject"]),
    ...mapActions("workspace", ["fetchWorkspaceAllUsers"]),
    handleSave() {
      this.$axios
        .post(`api/projects/${this.project.id}/members`, this.payload)
        .then(({ data }) => {
          this.setProject(data);
          this.storeProject(data);
          this.$emit("updated", data);
          this.appToast("Project users updated.", "success");
        })
        .finally(() => (this.open = false));
    },
    handleCancel() {
      this.selected = this.originals;
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>
